

$theme-colors: (
  'default': #A3A1B6,
  'primary': #de7125,
  'secondary': #6A6689,
  'info': #0dcaf0,
  'dark': #181747,
  'danger': #c00000,
  'error': #EA3B3B,
  'darker' : #1A1449,
  'light' : #F1F4F9,
  'hover' : #EEEDF1
);

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

h1 {
  font-family: Archivo;
  font-style: italic;
  font-weight: 900!important;
  font-size: 38px;
  line-height: 110%;
  color: #FFFFFF;
}

h2 {
  font-family: Archivo;
  font-weight: 900!important;
  font-style: italic;
  font-size: 26px;
  line-height: 130%;
  color: map-get($theme-colors, primary);
}

h3 {
  font-family: Archivo;
  font-weight: 900!important;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 120%; 
  color: map-get($theme-colors, primary);
}

h4 {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 28.8px */
  color: map-get($theme-colors, darker);
}

@mixin mobile() {
  @media only screen and (max-width: 767px) {
      @content;
  }
}
