@import '../variables.scss';

.nav-tabs {
    margin-bottom: 0px;
    border-bottom-width: 0px;
    border: none !important;
    border-bottom: none;
    .nav-item {
      background-color: map-get($theme-colors, secondary);
      border-radius: 4px 4px 0px 0px;
      margin: 4px 4px 0px 4px;
      button {
        border-radius: 4px 4px 0px 0px;
        height: 100%;
        white-space: nowrap; // Prevents text from breaking
    
       
      }
    }
    .nav-item:first-child {
      margin-left: 0px;
    }
    .nav-item:last-child {
      margin-right: 0px;
    }
    .nav-link {
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 25.6px */
      &:hover {
        color: map-get($theme-colors, primary);
        border: none !important;
      }
    }
  }

