@import '../variables.scss';

.first-word-color {
    color: map-get($theme-colors, primary);
    span {
      color: #ffffff;
    }
  }

  .no-jobs {
    color: map-get($theme-colors, secondary);
    font-family: Archivo;
    font-size: 18px;
    font-style: italic;
    font-weight: 900;
    line-height: 120%; /* 21.6px */
    
  }

  .primary-color {
    color: map-get($theme-colors, primary);
  }

  .secondary-color {
    color: map-get($theme-colors, secondary);
  }

  .default-color {
    color: map-get($theme-colors, default);
  }

  .error-color {
    color: map-get($theme-colors, error);
  }
  .darker-color {
    color: map-get($theme-colors, darker);
  }

  .text-icon-left {
    margin-right: 5px;
  }

  .tag {
      display: flex;
      height: fit-content;
      padding: 3px 12px 4px 12px !important;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 48px;
      color: #fff;
      font-weight: 600;
      width: fit-content;
  }
  .tag-grey {
      background: grey !important;
  }
  .tag-orange {
      background: #FFA500 !important;
  }
  .tag-green {
      background: #07B26A !important;
  }
  .tag-yellow {
      background: rgb(199, 199, 30) !important;
  }
  .tag-blue {
      background: #0074D9 !important;
  }
  .tag-red {
      background: #FF0000 !important;
  }
  .tag-black {
      background: #000000 !important;
  }

  a:hover {
    color: #c85906!important;
  }

a.link-grey {
 color: rgba(255, 255, 254, 0.856);
 font-weight: 400;
}

.span-with-click {
  color: #c85906!important;
    &:hover {
    cursor: pointer;
  }
}