@import '../variables.scss';


.btn {
  height: 54px;
  font-weight: 600;
  font-size: 14px;
}

.btn:disabled {
  background-color: map-get($theme-colors, default);
  border-color: map-get($theme-colors, default);
}

.btn:hover {
  background-color: map-get($theme-colors, hover);
  color: map-get($theme-colors, darker)!important;

}
.btn:active {
  background-color: map-get($theme-colors, hover)!important;
  color: map-get($theme-colors, darker);
}

.btn:not(.btn-link) {
  color: #fff;
  border-radius: 3px;
}

.btn.btn-inverse {
  color: map-get($theme-colors, darker);
  border: solid; 
  border-width: 1px;
  border-color: map-get($theme-colors, darker);
}

.btn-default {
  background-color: map-get($theme-colors, default);
  border-color: map-get($theme-colors, default);
}

.btn-primary {
  background-color: map-get($theme-colors, primary);
  border-color: map-get($theme-colors, primary);
  &:hover {
    .button-icon-right {
      filter: invert(100%);
    }
  }
}

.btn-secondary {
  background-color: map-get($theme-colors, darker);
  border-color: map-get($theme-colors, darker);
}

.button-icon-right {
  margin-left: 5px;
  margin-bottom: 3px;
}

.button-icon-left {
  margin-right: 5px;
  margin-bottom: 3px;
}



