@import '../variables.scss';

footer {
  background-color: map-get($theme-colors, darker);
  margin-top: 25px;
}

.footer-logo svg .st0 {
  color: #fff;
}

.footer-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

}
  
