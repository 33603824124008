

.header{
    background-image: url(../../assets/header.webp);
    background-size: cover;
    min-height: 150px;
    max-height: 200px;
    max-width: 100vw;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}



