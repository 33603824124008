@import '~bootstrap/scss/bootstrap.scss';
@import './variables.scss';
@import './components/button.scss';
@import './components/input.scss';
@import './components/navbar.scss';
@import './components/footer.scss';
@import './components/text.scss';
@import './components/tab.scss';
@import './components/header.scss';
@import './components/container.scss';
@import './components/map.scss';
@import './components/mobile.scss';
@import './components//progressBar.scss';
@import 'react-dropdown/style.css';
@import './components/modal.scss';

body {
  margin: 0;
  font-family: 'Poppins', 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-label {
  margin-top: 10px;
  font-weight: bold;
}
